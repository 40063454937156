'use strict';

(function() {
  class AddEditSiteCtrl {
    constructor($scope, Auth, Site, $uibModal, $state, usSpinnerService) {
      this.$scope = $scope;
      this.Site = Site;
      this.$uibModal = $uibModal;
      this.loading = false;
      this.usSpinnerService = usSpinnerService;
      Auth.isLoggedInAsync((loggedIn)=> {
        if (loggedIn) {
          // this.init();
        }else{
          $state.go('login');
        }
      });
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }

    addSite() {
      return site=> {
        this.$scope.$broadcast('siteAdded', site.data);
      }
    }

    openAddSiteModal(data = {}, update = false) {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/site/addEditSiteModal/addEditSiteModal.html',
        controller: 'AddEditSiteModalCtrl',
        controllerAs: 'aESite',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Add New Site'
          }
        }
      });
      modalInstance.result.then(this.addSite());
    }
  }

  angular.module('windmanagerApp')
    .controller('AddEditSiteCtrl', AddEditSiteCtrl);
})();

